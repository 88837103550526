<script setup lang="ts">
import type { PropType } from 'vue'
import type { Page } from '~/types'
import Parser from '~/libs/editorjs/parser'

const props = defineProps({
	item: {
		type: Object as PropType<Page>,
		required: true
	},
})

const parser = Parser()
const html = parser.parse(props.item.text)?.join('')
</script>

<template>
	<div
		v-html="html"
		class="editorjs-content"
	/>
</template>